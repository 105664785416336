import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";
import { withPrefix } from "gatsby";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const BlogPost = ({location}) =>{
    return <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="Paleo Shopping List For Beginners"
            description="If you're interested in following a paleo diet, we're here to help with a paleo shopping list you can start using right away!"
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                Paleo Shopping List For Beginners + FREE Printables
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/paleo-shopping-list.jpg" alt="Paleo Shopping List For Beginners"/>
            </ImageWithCaption>
        </HeroSection>
            <Paragraph>
                Nowadays, many folks are looking for new lifestyle changes that will bring them back to a more natural way of living.
            </Paragraph>
            <Paragraph>
                Our day to day lives are fast and demanding, and our bodies need replenishing from excessive amounts of stress.
            </Paragraph>
            <Paragraph>
                One popular way of getting back to basics is following a paleo diet.
            </Paragraph>
            <Paragraph>
                The paleo diet is a way of eating that follows the principles of what our ancestors who led a hunter gatherer lifestyle would have eaten.
            </Paragraph>
            <Paragraph>
                If you're interested in following a paleo diet, we're here to help with a paleo shopping list.🥦
            </Paragraph>
            <Paragraph>
                <b>Key takeaways from this article:</b>
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>The paleo diet is based on the Paleolithic lifestyle, focusing on plants and animal proteins.</UnorderedListItem>
                <UnorderedListItem>Benefits include a lower risk of diabetes type 2 and less strain on the environment.</UnorderedListItem>
                <UnorderedListItem>Drawbacks of a paleo diet include a high risk of heart disease and potential bone density issues.</UnorderedListItem>
                <UnorderedListItem>What to eat on a paleo diet.</UnorderedListItem>
                <UnorderedListItem>What to avoid on a paleo diet.</UnorderedListItem>
                <UnorderedListItem>Main difference between paleo and keto diets is that paleo allows whole-food carbs (while keto doesn't) and keto allows dairy and soy (while paleo doesn't).</UnorderedListItem>
            </UnorderedList>
            <BlogHeading2>
                Paleo shopping list printable
            </BlogHeading2>
            <Paragraph>
            <ExternalLink to = {withPrefix("Paleo Shopping List.pdf")}>Click here to download the printable paleo shopping list for your next shopping trip!</ExternalLink>
            </Paragraph>
            <BlogHeading2>
                What is a paleo diet?
            </BlogHeading2>
            <Paragraph>
                Paleo, or Paleolithic diet, as the name suggests, requires us to take a step back from our heavily industrialized lifestyles.
            </Paragraph>
            <Paragraph>
                It draws on the idea that eating as our ancestors did will contribute to our health.
            </Paragraph>
            <Paragraph>
                Some <ExternalLink to="https://health.clevelandclinic.org/pegan-diet/">benefits of the paleo diet</ExternalLink> include:
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Reducing the risk of type 2 diabetes by lowering blood sugar levels.</UnorderedListItem>
                <UnorderedListItem>Less strain on the environment, as organic and plant-based options are the main source of foods.</UnorderedListItem>
                <UnorderedListItem>Meeting most nutrient requirements.</UnorderedListItem>
                <UnorderedListItem>Avoiding processed foods, carbs, and sodium.</UnorderedListItem>
            </UnorderedList>
            <Paragraph>
                The paleo diet can also be good for weight loss. 
            </Paragraph>
            <Paragraph>
                ⚠️However, <ExternalLink to ="https://www.nature.com/articles/ejcn2013290">studies have shown</ExternalLink> that it is not an effective way to lose weight long term.⚠️
            </Paragraph>
            <Paragraph>
                Some other <ExternalLink to = "https://health.ucdavis.edu/blog/good-food/paleo-diet-what-it-is-and-why-its-not-for-everyone/2022/04">drawbacks of the paleo</ExternalLink> diet include:
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>It is high in saturated fats and could contribute to an increase in blood cholesterol levels, leading to heart problems.</UnorderedListItem>
                <UnorderedListItem>Low calcium intake, which is bad for the bones and increases the risk of osteoporosis.</UnorderedListItem>
                <UnorderedListItem>It is very low in carbs, so similar to the keto diet, it can lead to ketosis. It is recommended to follow such restricted meal plans only if they are approved by a doctor.</UnorderedListItem>
            </UnorderedList>
            <Paragraph>
                Another drawback of the paleo diet is that it is not suitable for people avoiding animal products.
            </Paragraph>
            <Paragraph>
                If you'd like some inspiration for a vegan diet instead, check out the <InternalLink to = "/vegan-pantry-staples/">vegan pantry staples</InternalLink> we've prepared!
            </Paragraph>
            <BlogHeading3>
                What are the rules for the paleo diet?
            </BlogHeading3>
            <Paragraph>
                The most basic way to follow the paleo diet is the 75% - 25% rule.
            </Paragraph>
            <Paragraph>
                It means that 75% of the time you eat plants (fruits, veggies, nuts, seeds) and 25% is reserved for proteins (meat, fish, eggs).
            </Paragraph>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/2023-1-paleo-plate.jpg" alt = "Plate filled with boiled eggs, cucumber, lettuce, avocado, nuts, and strawberries."/>
            </ImageWithCaption>
            <BlogHeading2>
                Paleo diet food list
            </BlogHeading2>
            <Paragraph>
                The most important aspect of the paleo diet is to focus on plants and protein. 
            </Paragraph>
            <Paragraph>
                Here are some ideas for your paleo grocery list:
            </Paragraph>
            <BlogHeading3>
                Vegetables
            </BlogHeading3>
            <Paragraph>
                Veggies should be at the core of your paleo meal plan.
            </Paragraph>
            <Paragraph>
                You can eat pretty much any vegetable on the paleo diet, and here some suggestions:
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Tomatoes</UnorderedListItem>
                <UnorderedListItem>Pepper</UnorderedListItem>
                <UnorderedListItem>Cucumber</UnorderedListItem>
                <UnorderedListItem>Broccoli</UnorderedListItem>
                <UnorderedListItem>Cauliflower</UnorderedListItem>
                <UnorderedListItem>Carrots</UnorderedListItem>
                <UnorderedListItem>Spinach</UnorderedListItem>
                <UnorderedListItem>Lettuce</UnorderedListItem>
                <UnorderedListItem>Cabbage</UnorderedListItem>
                <UnorderedListItem>Kale</UnorderedListItem>
                <UnorderedListItem>Brussels sprouts</UnorderedListItem>
            </UnorderedList>
            <BlogHeading3>
                Fruit
            </BlogHeading3>
            <Paragraph>
                Generally all fruit is allowed on the paleo diet.
            </Paragraph>
            <Paragraph>
                However, it's always good to focus on fruit with a low glycemic index, such as:
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Apples</UnorderedListItem>
                <UnorderedListItem>Berries (blackberries, strawberries, cherries)</UnorderedListItem>
                <UnorderedListItem>Citrus fruits (oranges, grapefruit, lemons)</UnorderedListItem>
                <UnorderedListItem>Pears</UnorderedListItem>
                <UnorderedListItem>Plums</UnorderedListItem>
            </UnorderedList>
            <BlogHeading3>
                Nuts & seeds
            </BlogHeading3>
            <Paragraph>
                Nuts and seeds are great sources of healthy fats and plant based proteins.
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Almonds</UnorderedListItem>
                <UnorderedListItem>Cashews</UnorderedListItem>
                <UnorderedListItem>Sesame seeds</UnorderedListItem>
                <UnorderedListItem>Peanuts</UnorderedListItem>
            </UnorderedList>
            <BlogHeading3>
                Animal proteins
            </BlogHeading3>
            <Paragraph>
                Animal proteins are what will give you that necessary sensation of fullness  on the paleo diet. Without them, you’d likely feel hungry almost all of the time.
            </Paragraph>
            <Paragraph>
                If you can, try to focus on meats that are organic and grass fed, and fish that is wild caught.
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Meat (pork, lamb, beef)</UnorderedListItem>
                <UnorderedListItem>Poultry (chicken, turkey)</UnorderedListItem>
                <UnorderedListItem>Eggs</UnorderedListItem>
                <UnorderedListItem>Fish</UnorderedListItem>
            </UnorderedList>
            <BlockQuote>
                <b>Pro tip:</b> <InternalLink to="/#download-section">Download a free shopping list app</InternalLink> to take your paleo list with you next time you go to the store!
            </BlockQuote>
            <BlogHeading3>
                Healthy fats
            </BlogHeading3>
            <Paragraph>
                Even though trans fats are not recommended on any healthy diet, it is still important to eat some of the good type of fats, such as:
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Olive oil</UnorderedListItem>
                <UnorderedListItem>Avocado</UnorderedListItem>
                <UnorderedListItem>Sesame oil</UnorderedListItem>
                <UnorderedListItem>Nut butters (almond butter, peanut butter, tahini)</UnorderedListItem>
            </UnorderedList>
            <BlogHeading3>
                Foods you can eat in moderation
            </BlogHeading3>
            <Paragraph>
                Although you should focus on plants and proteins, here some foods you can include in your paleo diet in moderation:
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Quinoa</UnorderedListItem>
                <UnorderedListItem>Whole grain rice</UnorderedListItem>
                <UnorderedListItem>Beans and lentils</UnorderedListItem>
                <UnorderedListItem>Non-sugar sweeteners such as honey, maple syrup, stevia, coconut sugar</UnorderedListItem>
            </UnorderedList>
            <BlockQuote>
                <b>Read more:</b> Here is another diet similar to paleo, and a shopping list for it: <InternalLink to ="/blood-type-o-shopping-list/">the blood type O shopping list</InternalLink>.
            </BlockQuote>
            <BlogHeading2>
                What is not allowed on paleo?
            </BlogHeading2>
            <Paragraph>
                You can get the short answer to the question of what is not allowed on the paleo diet by just asking yourself: did our hunter gatherer ancestors have the access to this food you feel like eating? 
            </Paragraph>
            <Paragraph>
                So when in doubt, that is a great way to assess what to add to your paleo meal plan.
            </Paragraph>
            <Paragraph>
                Here is a list of all foods you should be avoiding on the paleo diet:
            </Paragraph>
            <BlogHeading3>
                Dairy
            </BlogHeading3>
            <Paragraph>
                Although our ancestors did have access to dairy, it was in a very limited amount and not the processed type of dairy that is available in stores today. 
            </Paragraph>
            <Paragraph>
                So make sure you are avoiding dairy on a paleo diet.
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Milk</UnorderedListItem>
                <UnorderedListItem>Yogurt</UnorderedListItem>
                <UnorderedListItem>Cheese</UnorderedListItem>
            </UnorderedList>
            <BlogHeading3>
                Processed foods
            </BlogHeading3>
            <Paragraph>
                We all know that processed foods are never recommended if you want to follow a healthy lifestyle.
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Chips</UnorderedListItem>
                <UnorderedListItem>Crackers</UnorderedListItem>
                <UnorderedListItem>Cereals</UnorderedListItem>
                <UnorderedListItem>Microwave ready meals</UnorderedListItem>
                <UnorderedListItem>Instant noodles</UnorderedListItem>
                <UnorderedListItem>Syrups and dressings</UnorderedListItem>
            </UnorderedList>
            <BlogHeading3>
                Fast food
            </BlogHeading3>
            <Paragraph>
                Avoiding fast food should also be a no-brainer
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Hamburgers</UnorderedListItem>
                <UnorderedListItem>Cheeseburgers</UnorderedListItem>
                <UnorderedListItem>French fries</UnorderedListItem>
                <UnorderedListItem>Chicken nuggets</UnorderedListItem>
                <UnorderedListItem>Pizza</UnorderedListItem>
            </UnorderedList>
            <ImageWithCaption>
                <StaticImage src = "../assets/images/blogs/2023-1-woman-eating-fast-food.jpg" alt = "Young woman standing outside eating a hamburger."/>
            </ImageWithCaption>
            <BlogHeading3>
                Sweets
            </BlogHeading3>
            <Paragraph>
                Sweets that are heavily processed and contain a high amount of refined sugar should be avoided too.
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Chocolate</UnorderedListItem>
                <UnorderedListItem>Ice cream</UnorderedListItem>
                <UnorderedListItem>Cakes</UnorderedListItem>
                <UnorderedListItem>Cookies</UnorderedListItem>
                <UnorderedListItem>Muffins</UnorderedListItem>
                <UnorderedListItem>Cupcakes</UnorderedListItem>
                <UnorderedListItem>Donuts</UnorderedListItem>
            </UnorderedList>
            <BlogHeading3>
                Grains
            </BlogHeading3>
            <Paragraph>
                Grains are high in glycemic index and are usually overly processed. Avoid grains such as:
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Bread and pastries</UnorderedListItem>
                <UnorderedListItem>Oatmeal</UnorderedListItem>
                <UnorderedListItem>Anything made of wheat or barley</UnorderedListItem>
            </UnorderedList>
            <BlogHeading2>
                What is the 85/15 rule in paleo?
            </BlogHeading2>
            <Paragraph>
                Given that paleo is such a restrictive diet, it can be difficult to maintain long term.
            </Paragraph>
            <Paragraph>
                That is why even its founder, <ExternalLink to = "https://thepaleodiet.com/explaining-the-85-15-principle">Dr Cordain</ExternalLink>, insists that you can still achieve good weight loss results even when not following the diet 100% of the time.
            </Paragraph>
            <Paragraph>
                The 85/15 rule basically means that you follow the paleo diet 85% of the time. The remaining 15% of your time, you allow yourself for some “cheating” or indulging in foods that are technically not allowed on the paleo diet.
            </Paragraph>
            <Paragraph>
                You can do so by adding one or two “cheat” meals every week.
            </Paragraph>
            <BlogHeading2>
                Is paleo healthier than keto?
            </BlogHeading2>
            <Paragraph>
                It can be difficult to distinguish paleo and keto diets because both of them eliminate added sugar and emphasize healthy fats.
            </Paragraph>
            <Paragraph>
                The main difference is that paleo allows whole-food carbs, while keto allows dairy and soy.
            </Paragraph>
            <Paragraph>
                Here is a neat table that will help you better distinguish what's allowed on paleo and what on keto 👇
            </Paragraph>
            <ImageWithCaption>
                <StaticImage src ="../assets/images/blogs/2023-1-paleo-vs keto-comparison-table.jpg" alt ="Paleo vs Keto comparison"/>
            </ImageWithCaption>
            <Paragraph>
                Although both paleo and keto are healthy diets, <ExternalLink to = "https://www.healthline.com/nutrition/paleo-vs-keto">paleo is considered a slightly healthier option</ExternalLink>.
            </Paragraph>
            <Paragraph>
                That is because keto is more restrictive and not tolerated by certain individuals, such as those with a high risk of cardiovascular disease. (The keto diet is high in trans fats and thus not recommended for patients with heart or cardiovascular issues.)
            </Paragraph>
            <Paragraph>
                Paleo is a bit less restrictive and slightly easier to maintain than the keto diet.
            </Paragraph>
            <Paragraph>
                Either way, it is recommended that both keto and paleo diets should be followed only after consulting with a medical professional.
            </Paragraph>
            <Paragraph>
                For more inspiration for the keto diet, check out our <InternalLink to = "/keto-pantry-staples/">keto shopping list</InternalLink>.
            </Paragraph>
            <BlogHeading2>
                Conclusion
            </BlogHeading2>
            <Paragraph>
                The paleo diet can bring about a number of health benefits, although there are significant drawbacks too.
            </Paragraph>
            <Paragraph>
                If you decide to take on the paleo eating plan, you can still achieve health and weight loss benefits even with the 85/15 approach, following the diet only 85% of the time.
            </Paragraph>
            <Paragraph>
                The most important thing is to stay away from sugars and processed foods, even if you are on a slight cheat day.
            </Paragraph>
            <Paragraph>
                The paleo diet, similar to keto, is quite restrictive, so make sure you are consulting a health professional if you decide to follow it.
            </Paragraph>
            <Paragraph>
                We hope this paleo diet grocery list has inspired you to start a journey of more healthy living!
            </Paragraph>
    </StyledMainContent>
    </BlogPostWrapper>
}

export default BlogPost;